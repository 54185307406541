import axios from "axios";
import { getToken } from "../localstorage";

const API_URL = "https://api.rewardy.net";

class Api {
  constructor() {
    this.url = API_URL;
  }

  getHeaders(config = {}) {
    const token = getToken();

    return {
      headers: {
        ...(token && { Authorization: token.replaceAll('"', "") }),
        "Content-Type": "application/json",
      },
      ...config,
    };
  }

  login(body) {
    return axios.post(`${this.url}/api/admin/login`, body, this.getHeaders());
  }

  payouts(offset = 0, limit = 5) {
    const params = {
      params: {
        offset,
        limit,
      },
    };

    return axios.get(`${this.url}/api/admin/payouts`, {
      ...params,
      ...this.getHeaders(),
    });
  }

  payout(id) {
    return axios.get(`${this.url}/api/admin/payouts/${id}`, {
      ...this.getHeaders(),
    });
  }

  completePayoutRequest(id) {
    return axios.get(`${this.url}/api/admin/payouts/${id}/complete`, {
      ...this.getHeaders(),
    });
  }

  user(id) {
    return axios.get(`${this.url}/api/admin/users/${id}`, {
      ...this.getHeaders(),
    });
  }

  userByCode(code) {
    return axios.get(`${this.url}/api/admin/users_by_code/${code}`, {
      ...this.getHeaders(),
    });
  }

  userTransactions(userId, offset = 0, limit = 5) {
    const params = {
      params: {
        offset,
        limit,
      },
    };

    return axios.get(`${this.url}/api/admin/users/${userId}/transactions`, {
      ...params,
      ...this.getHeaders(),
    });
  }

  userClicks(userId, offset = 0, limit = 5) {
    const params = {
      params: {
        offset,
        limit,
      },
    };

    return axios.get(`${this.url}/api/admin/users/${userId}/clicks`, {
      ...params,
      ...this.getHeaders(),
    });
  }

  users(offset = 0, limit = 5) {
    const params = {
      params: {
        offset,
        limit,
      },
    };

    return axios.get(`${this.url}/api/admin/users`, {
      ...params,
      ...this.getHeaders(),
    });
  }

  adgateOffers(
    offset = 0,
    limit = 5,
    os_type = -1,
    order_by = "id",
    country = null
  ) {
    const params = {
      params: {
        offset,
        limit,
        os_type,
        order_by,
        country,
      },
    };

    return axios.get(`${this.url}/api/admin/offers/adgate`, {
      ...params,
      ...this.getHeaders(),
    });
  }
}

export default new Api();
