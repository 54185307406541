import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Backdrop,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";

import Title from "../components/Title";
import { getPayoutStatus, getPayoutStatusColor } from "../const";
import Api from "../services/api";
import { numberWithCommas, tsToDate } from "../util";

export default function Payouts() {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const payouts = useQuery(
    ["payouts", page, rowsPerPage],
    () => Api.payouts(page * rowsPerPage, rowsPerPage),
    { keepPreviousData: true }
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const rows = payouts.data?.data.payouts ?? [];
  const totalRows = payouts.data?.data.total ?? rows.length;

  return (
    <Grid container spacing={3}>
      <Backdrop
        open={payouts.isLoading}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Grid item xs>
        <Paper sx={{ overflow: "hidden", p: 2 }}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Title>Payouts</Title>
            </Grid>
            <Grid item>
              {payouts.isFetching && <CircularProgress size={20} />}
            </Grid>
          </Grid>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell nowrap="true">User ID</TableCell>
                  <TableCell nowrap="true">Paypal Email</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Points</TableCell>
                  <TableCell align="right">Created Date</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.user_id}</TableCell>
                    <TableCell>{row.user.paypal_email}</TableCell>
                    <TableCell>
                      <Chip
                        size="small"
                        label={getPayoutStatus(row.status)}
                        color={getPayoutStatusColor(row.status)}
                      />
                    </TableCell>
                    <TableCell>{row.type_str}</TableCell>
                    <TableCell>{numberWithCommas(row.points)}</TableCell>
                    <TableCell align="right" nowrap="true">
                      {tsToDate(row.ts_created)}
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        size="small"
                        variant="contained"
                        endIcon={<ChevronRightIcon />}
                        onClick={() => {
                          navigate(`/payouts/${row.id}`);
                        }}
                      >
                        Detail
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow></TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            showFirstButton={true}
            rowsPerPageOptions={[5, 10, 25]}
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}
