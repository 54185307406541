import PropTypes from "prop-types";

import { LoadingButton } from "@mui/lab";
import {
  Chip,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";

import Title from "../components/Title";
import { getPayoutStatus, getPayoutStatusColor } from "../const";
import { numberWithCommas, tsToDate } from "../util";

function PayoutInfo({ payout, onClickComplete, loading }) {
  return (
    <Paper sx={{ overflow: "hidden", p: 2 }}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Title>Payout Info</Title>
        </Grid>
        <Grid item>
          <LoadingButton
            disabled={payout.status !== 0}
            variant="contained"
            color="success"
            onClick={onClickComplete}
            loading={loading}
          >
            Complete
          </LoadingButton>
        </Grid>
      </Grid>
      <TableContainer>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell variant="head" sx={{ minWidth: 140, width: 140 }}>
                Payout ID
              </TableCell>
              <TableCell sx={{ minWidth: 200 }}>{payout.id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Paypal Email</TableCell>
              <TableCell>{payout.user.paypal_email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Title</TableCell>
              <TableCell>{payout.title}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Status</TableCell>
              <TableCell>
                <Chip
                  size="small"
                  label={getPayoutStatus(payout.status)}
                  color={getPayoutStatusColor(payout.status)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Type</TableCell>
              <TableCell>{payout.type_str}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Points</TableCell>
              <TableCell>{numberWithCommas(payout.points)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Created Date</TableCell>
              <TableCell>{tsToDate(payout.ts_created)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

PayoutInfo.propTypes = {
  payout: PropTypes.object.isRequired,
  onClickComplete: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default PayoutInfo;
