import { useMutation, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { Backdrop, Button, CircularProgress, Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { useState } from "react";
import PayoutInfo from "../components/PayoutInfo";
import UserClicks from "../components/UserClicks";
import UserInfo from "../components/UserInfo";
import UserTransactions from "../components/UserTransactions";
import Api from "../services/api";

export default function Payout() {
  const { payoutId } = useParams();
  const [confirmDialogIsOpen, setConfirmDialogIsOpen] = useState(false);

  const payout = useQuery(["payout", parseInt(payoutId)], () =>
    Api.payout(payoutId)
  );

  const payoutData = payout.data?.data.payout;
  const userId = payoutData?.user_id;

  const completePayout = useMutation(
    () => Api.completePayoutRequest(payoutId),
    {
      onSuccess: () => {
        payout.refetch();
      },
    }
  );

  const handleClose = (result) => {
    setConfirmDialogIsOpen(false);

    if (result === true) {
      completePayout.mutate();
    }
  };

  if (payout.isLoading) {
    return (
      <Backdrop
        open={true}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (payout.isError) {
    return <div>Error: {payout.error.message}</div>;
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={4}>
          <Grid item pb={3}>
            <PayoutInfo
              payout={payoutData}
              onClickComplete={() => setConfirmDialogIsOpen(true)}
              loading={completePayout.isLoading}
            />
          </Grid>
          <Grid item>
            <UserInfo user={payoutData.user} />
          </Grid>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Grid item pb={3}>
            <UserTransactions userId={userId} />
          </Grid>
          <Grid item>
            <UserClicks userId={userId} />
          </Grid>
        </Grid>
      </Grid>
      <Dialog open={confirmDialogIsOpen} onClose={handleClose}>
        <DialogTitle>Complete Payment Request</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to approve the payment request ? Notification
            will be sent to the user.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} autoFocus>
            Cancel
          </Button>
          <Button onClick={() => handleClose(true)}>OK</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
