import { useQuery } from "@tanstack/react-query";
import PropTypes from "prop-types";
import { useState } from "react";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";

import Title from "../components/Title";
import Api from "../services/api";
import { numberWithCommas, tsToDate } from "../util";

function UserTransactions({ userId }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const users = useQuery(
    ["user.transactions", userId, page, rowsPerPage],
    () => Api.userTransactions(userId, page * rowsPerPage, rowsPerPage),
    {
      keepPreviousData: true,
      enabled: !!userId,
    }
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const rows = users.data?.data.transactions ?? [];
  const totalRows = users.data?.data.total ?? rows.length;

  return (
    <Paper sx={{ overflow: "hidden", p: 2 }}>
      <Title>User Transactions</Title>
      <TableContainer sx={{ maxHeight: 350 }}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Title</TableCell>
              <TableCell align="right">Points</TableCell>
              <TableCell align="right">Created Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((r) => (
              <TableRow key={r.id}>
                <TableCell>{r.id}</TableCell>
                <TableCell nowrap="true">{r.title}</TableCell>
                <TableCell align="right">
                  {numberWithCommas(r.points)}
                </TableCell>
                <TableCell align="right" nowrap="true">
                  {tsToDate(r.ts_created)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        showFirstButton={true}
        rowsPerPageOptions={[5, 10, 25]}
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

UserTransactions.propTypes = {
  userId: PropTypes.number.isRequired,
};

export default UserTransactions;
