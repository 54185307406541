import { createContext, useContext } from "react";

import useLocalStorage from "./useLocalStorage";

const AuthContext = createContext();

function useProvideAuth() {
  const [token, setToken] = useLocalStorage("token", null);

  const login = (tokenData, callback) => {
    setToken(tokenData);
    callback();
  };

  const logout = (callback) => {
    setToken(null);
    callback();
  };

  return {
    token,
    login,
    logout,
  };
}

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
  return useContext(AuthContext);
};
