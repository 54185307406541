import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";

import Title from "../components/Title";
import { getGender } from "../const";
import Api from "../services/api";
import { tsToDate } from "../util";

export default function Users() {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const users = useQuery(
    ["users", page, rowsPerPage],
    () => Api.users(page * rowsPerPage, rowsPerPage),
    { keepPreviousData: true }
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const rows = users.data?.data.users ?? [];
  const totalRows = users.data?.data.total ?? rows.length;

  return (
    <Grid container spacing={3}>
      <Backdrop
        open={users.isLoading}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Grid item xs>
        <Paper sx={{ overflow: "hidden", p: 2 }}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Title>Users</Title>
            </Grid>
            <Grid item>
              {users.isFetching && <CircularProgress size={20} />}
            </Grid>
          </Grid>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Code</TableCell>
                  <TableCell>Country</TableCell>
                  <TableCell>Language</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Gender</TableCell>
                  <TableCell>Age</TableCell>
                  <TableCell nowrap="true">App Version</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>OS</TableCell>
                  <TableCell nowrap="true">Ad ID</TableCell>
                  <TableCell align="right">Login Date</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.code}</TableCell>
                    <TableCell>{row.extra.country_code}</TableCell>
                    <TableCell>{row.extra.language_code}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{getGender(row.gender)}</TableCell>
                    <TableCell nowrap="true">{row.age}</TableCell>
                    <TableCell nowrap="true">
                      {row.extra.app_version}+{row.extra.app_build_number}
                    </TableCell>
                    <TableCell nowrap="true">
                      {row.extra.manufacturer} {row.extra.brand}{" "}
                      {row.extra.model}
                    </TableCell>
                    <TableCell nowrap="true">
                      {row.extra.os} {row.extra.os_release}
                    </TableCell>
                    <TableCell>
                      {row.extra.advertising_id === "" ? (
                        <ClearIcon fontSize="small" color="error" />
                      ) : (
                        <DoneIcon fontSize="small" color="success" />
                      )}
                    </TableCell>
                    <TableCell align="right" nowrap="true">
                      {tsToDate(row.ts_updated)}
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        size="small"
                        variant="contained"
                        endIcon={<ChevronRightIcon />}
                        onClick={() => {
                          navigate(`/users/${row.code}`);
                        }}
                      >
                        Detail
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow></TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            showFirstButton={true}
            rowsPerPageOptions={[5, 10, 25]}
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}
