import { useQuery } from "@tanstack/react-query";
import PropTypes from "prop-types";
import { useState } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import {
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";

import Title from "../components/Title";
import { getOfferType } from "../const";
import Api from "../services/api";
import { numberWithCommas, tsToDate } from "../util";

function UserClicks({ userId }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const users = useQuery(
    ["user.clicks", userId, page, rowsPerPage],
    () => Api.userClicks(userId, page * rowsPerPage, rowsPerPage),
    {
      keepPreviousData: true,
      enabled: !!userId,
    }
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const rows = users.data?.data.clicks ?? [];
  const totalRows = users.data?.data.total ?? rows.length;

  return (
    <Paper sx={{ overflow: "hidden", p: 2 }}>
      <Title>User Clicks</Title>
      <TableContainer sx={{ maxHeight: 350 }}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell nowrap="true">Offer Type</TableCell>
              <TableCell nowrap="true">Offer ID</TableCell>
              <TableCell nowrap="true">Offer Points</TableCell>
              <TableCell>Postback</TableCell>
              <TableCell nowrap="true">Offer Title</TableCell>
              <TableCell nowrap="true">Physical Device</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>OS</TableCell>
              <TableCell>IP</TableCell>
              <TableCell align="right">Click Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((r) => (
              <TableRow key={r.id}>
                <TableCell>{getOfferType(r.offer_type)}</TableCell>
                <TableCell>{r.offer_id}</TableCell>
                <TableCell>{numberWithCommas(r.offer_points)}</TableCell>
                <TableCell>
                  {r.postback_id === null ? (
                    <ClearIcon fontSize="small" color="error" />
                  ) : (
                    <DoneIcon fontSize="small" color="success" />
                  )}
                </TableCell>
                <TableCell nowrap="true">{r.offer_title}</TableCell>
                <TableCell>
                  {r.extra.is_physical_device ? "YES" : "NO"}
                </TableCell>
                <TableCell nowrap="true">
                  {r.extra.manufacturer} {r.extra.brand} {r.extra.model}
                </TableCell>
                <TableCell nowrap="true">
                  {r.extra.os} {r.extra.os_release}
                </TableCell>
                <TableCell>
                  <Link
                    target="_blank"
                    href={`https://www.ipqualityscore.com/vpn-ip-address-check/lookup/${r.ip}`}
                  >
                    {r.ip}
                  </Link>
                </TableCell>
                <TableCell align="right" nowrap="true">
                  {tsToDate(r.ts_updated)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        showFirstButton={true}
        rowsPerPageOptions={[5, 10, 25]}
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

UserClicks.propTypes = {
  userId: PropTypes.number.isRequired,
};

export default UserClicks;
