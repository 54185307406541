import PropTypes from "prop-types";

import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";

import { Link } from "react-router-dom";
import Title from "../components/Title";
import { getGender } from "../const";
import { tsToDate } from "../util";

function UserInfo({ user }) {
  return (
    <Paper sx={{ overflow: "hidden", p: 2 }}>
      <Title>User Info</Title>
      <TableContainer>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell variant="head" sx={{ minWidth: 140, width: 140 }}>
                User ID
              </TableCell>
              <TableCell sx={{ minWidth: 200 }}>{user.id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">User Code</TableCell>
              <TableCell>
                <Link to={`/users/${user.code}`}>{user.code}</Link>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Country</TableCell>
              <TableCell>{user.extra.country_code}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Language</TableCell>
              <TableCell>{user.extra.language_code}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Name</TableCell>
              <TableCell>{user.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Gender</TableCell>
              <TableCell>{getGender(user.gender)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Age</TableCell>
              <TableCell>{user.age}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">App Version</TableCell>
              <TableCell>
                {user.extra.app_version}+{user.extra.app_build_number}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Phone</TableCell>
              <TableCell>
                {user.extra.manufacturer} {user.extra.brand} {user.extra.model}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">OS</TableCell>
              <TableCell>
                {user.extra.os} {user.extra.os_release}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Ad ID</TableCell>
              <TableCell>
                {user.extra.advertising_id === "" ? (
                  <ClearIcon fontSize="small" color="error" />
                ) : (
                  <DoneIcon fontSize="small" color="success" />
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Created Date</TableCell>
              <TableCell>{tsToDate(user.ts_created)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Login Date</TableCell>
              <TableCell>{tsToDate(user.ts_updated)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

UserInfo.propTypes = {
  user: PropTypes.object.isRequired,
};

export default UserInfo;
