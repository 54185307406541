import { Route, Routes } from "react-router-dom";

import DashboardLayout from "./components/DashboardLayout";
import RequireAuth from "./components/RequireAuth";

import AdgateOffers from "./pages/AdgateOffers";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Payout from "./pages/Payout";
import Payouts from "./pages/Payouts";
import User from "./pages/User";
import Users from "./pages/Users";

export default function App() {
  return (
    <Routes>
      <Route
        element={
          <RequireAuth>
            <DashboardLayout />
          </RequireAuth>
        }
      >
        <Route index element={<Home />} />
        <Route path="payouts">
          <Route index element={<Payouts />} />
          <Route path=":payoutId" element={<Payout />} />
        </Route>
        <Route path="users">
          <Route index element={<Users />} />
          <Route path=":userCode" element={<User />} />
        </Route>
        <Route path="offers">
          <Route path="adgate" element={<AdgateOffers />} />
        </Route>
      </Route>
      <Route path="login" element={<Login />} />
    </Routes>
  );
}
