import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import reportWebVitals from "./reportWebVitals";

import App from "./App";
import { ProvideAuth } from "./hooks/useAuth";

const root = createRoot(document.getElementById("root"));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
    },
  },
});

const theme = createTheme({
  palette: {
    mode: "light",
  },
});

root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ProvideAuth>
          <App />
        </ProvideAuth>
      </BrowserRouter>
      <ReactQueryDevtools />
    </QueryClientProvider>
  </ThemeProvider>
);

reportWebVitals();
