import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { Backdrop, CircularProgress, Grid } from "@mui/material";

import UserClicks from "../components/UserClicks";
import UserInfo from "../components/UserInfo";
import UserTransactions from "../components/UserTransactions";
import Api from "../services/api";

export default function User() {
  const { userCode } = useParams();

  const user = useQuery(["payout", userCode], () => Api.userByCode(userCode));

  const userData = user.data?.data.user;

  if (user.isLoading) {
    return (
      <Backdrop
        open={true}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (user.isError) {
    return <div>Error: {user.error.message}</div>;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={4}>
        <Grid item pb={3}>
          <UserInfo user={userData} />
        </Grid>
      </Grid>
      <Grid item xs={12} lg={8}>
        <Grid item pb={3}>
          <UserTransactions userId={userData.id} />
        </Grid>
        <Grid item>
          <UserClicks userId={userData.id} />
        </Grid>
      </Grid>
    </Grid>
  );
}
