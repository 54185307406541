import { Grid } from "@mui/material";

import RecentPayouts from "../components/RecentPayouts";

export default function Home() {
  return (
    <Grid container spacing={3}>
      <Grid item xs>
        <RecentPayouts />
      </Grid>
    </Grid>
  );
}
