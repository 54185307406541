export const drawerWidth = 240;

export const getGender = (gender) => {
  if (gender === 1) {
    return "Male";
  }
  if (gender === 2) {
    return "Female";
  }

  return "-";
};

export const getPayoutStatus = (status) => {
  if (status === 0) {
    return "Pending";
  }
  if (status === 1) {
    return "Completed";
  }
  if (status === 2) {
    return "Cancelled";
  }

  return "Unknown";
};

export const getPayoutStatusColor = (status) => {
  if (status === 0) {
    return "warning";
  }
  if (status === 1) {
    return "success";
  }
  if (status === 2) {
    return "error";
  }

  return "Unknown";
};

export const getOfferType = (type) => {
  if (type === 1) {
    return "Direct";
  }
  if (type === 2) {
    return "Adgate";
  }
  if (type === 3) {
    return "Fyber";
  }
  if (type === 4) {
    return "AdGem";
  }
  if (type === 5) {
    return "PollFish";
  }
  if (type === 6) {
    return "FyberVideo";
  }

  return "Unknown";
};

export const getOSType = (type) => {
  if (type === 0) {
    return "Both";
  }
  if (type === 1) {
    return "IOS";
  }
  if (type === 2) {
    return "Android";
  }

  return "Unknown";
};

export const getDeviceType = (type) => {
  if (type === 0) {
    return "Both";
  }
  if (type === 1) {
    return "Phone";
  }
  if (type === 2) {
    return "Tablet";
  }

  return "Unknown";
};
