import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Button,
  Chip,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { getPayoutStatus, getPayoutStatusColor } from "../const";
import Api from "../services/api";
import { numberWithCommas, tsToDate } from "../util";
import Title from "./Title";

export default function RecentPayouts() {
  const navigate = useNavigate();

  const payouts = useQuery(["payouts", 1, 5], () => Api.payouts(0, 5));

  const rows = payouts.data?.data.payouts ?? [];

  return (
    <Paper sx={{ overflow: "hidden", p: 2 }}>
      <Title>Recent Payouts</Title>
      <TableContainer sx={{ maxHeight: 500, mb: 2 }}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell nowrap="true">User ID</TableCell>
              <TableCell nowrap="true">Paypal Email</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Points</TableCell>
              <TableCell align="right">Created Date</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.user_id}</TableCell>
                <TableCell nowrap="true">{row.user.paypal_email}</TableCell>
                <TableCell>
                  <Chip
                    size="small"
                    label={getPayoutStatus(row.status)}
                    color={getPayoutStatusColor(row.status)}
                  />
                </TableCell>
                <TableCell>{row.type_str}</TableCell>
                <TableCell>{numberWithCommas(row.points)}</TableCell>
                <TableCell align="right" nowrap="true">
                  {tsToDate(row.ts_created)}
                </TableCell>
                <TableCell align="right">
                  <Button
                    size="small"
                    variant="contained"
                    endIcon={<ChevronRightIcon />}
                    onClick={() => {
                      navigate(`/payouts/${row.id}`);
                    }}
                  >
                    Detail
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Link color="primary" href="/payouts">
        See more payouts
      </Link>
    </Paper>
  );
}
